import React from 'react';
import { Flex } from '@rebass/grid';
import withLayout from '../layout';

import { ContactData, Map } from '../components/contactPage';
import CallUs from '../components/CallUs';

const Contact = props => {
  return (
    <Flex width="100vw" flexDirection="column" alignItems="center">
      <ContactData />
      <CallUs section />
      <Map />
    </Flex>
  );
};

const customProps = {
  localeKey: 'contact',
};

export default withLayout(customProps)(Contact);
