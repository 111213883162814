import React from 'react';

const MapPin = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.219 46.75">
      <path
        d="M14.609 0A14.618 14.618 0 0 0 1.333 20.716l11.128 24.148c.534 1.161 1.3 1.886 2.149 1.886s1.615-.725 2.149-1.883l11.128-24.151A14.618 14.618 0 0 0 14.609 0zm0 23.375a8.766 8.766 0 1 1 8.766-8.766 8.768 8.768 0 0 1-8.766 8.766z"
        data-name="Path 46"
        fill="#60882a"
      />
    </svg>
  );
};

export default MapPin;
