import React from 'react';

import { Box } from '@rebass/grid';

import Flower from '../Flowers';
import Waves from '../../assets/waves';

const LeftFlowers = ({ className }) => {
  return (
    <Box
      className={className}
      css={`
        position: relative;
      `}
    >
      <Flower.Right1
        css={`
          position: absolute;
          bottom: -120px;
          left: 25px;
        `}
      />
      <Waves.ContactRight
        css={`
          position: absolute;
        `}
      />
    </Box>
  );
};

export default LeftFlowers;
