import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Flex, Box } from '@rebass/grid';

import { height, minWidth, maxWidth, position, compose, zIndex, top, left } from 'styled-system';

import CatOnWaves from '../CatOnWaves';
import Link from '../Link';

import Pin from '../../assets/map-pin';
import { movePin } from '../../styles/animations';

import { GOOGLE_MAPS } from '../../constants';

const Map = () => {
  return (
    <StaticQuery
      query={mapQuery}
      render={data => (
        <Flex
          as="section"
          css={`
            position: relative;
          `}
          width={1}
          mt={{ mobile: 100, laptop: 150 }}
          mb={105}
          flexDirection="column"
          alignItems="center"
        >
          <Box
            as={Link}
            external
            to={GOOGLE_MAPS}
            css={compose(
              minWidth,
              maxWidth,
              height,
              position,
            )}
            width={1}
            minWidth={1280}
            maxWidth={1920}
            height="100%"
            position="relative"
          >
            <Pin
              css={`
                ${compose(
                  zIndex,
                  left,
                  top,
                  height,
                )}
                position: absolute;
                animation: ${movePin} 1.2s infinite ease-in-out;
              `}
              zIndex={1}
              left={{ mobile: '50%', laptop: '50.5%' }}
              top={{ mobile: '21%', laptop: '23%' }}
              height={{ mobile: '12%', laptop: '10%' }}
            />
            <Img fluid={data.map.childImageSharp.fluid} />
          </Box>
          <CatOnWaves
            css={`
              position: absolute;
              bottom: -105px;
            `}
          />
        </Flex>
      )}
    />
  );
};

const mapQuery = graphql`
  query {
    map: file(relativeDirectory: { eq: "contact" }, name: { eq: "map" }) {
      childImageSharp {
        fluid(maxWidth: 1920, srcSetBreakpoints: [1280, 1440], quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Map;
