import React from 'react';

import { compose, space, color, fontSize, maxWidth } from 'styled-system';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Flex, Box } from '@rebass/grid';

import LookAtButton from '../LookAtButton';
import LeftFlowers from './LeftFlowers';
import RightFlowers from './RightFlowers';

import { enHour } from '../../helpers';
import { TITLE, ADRESS, WORKING_HOURS } from '../../constants';

const WorkingHoursRow = ({ day, workingHours: { start, end }, locale }) => {
  const formatTime = time => (locale === 'en' ? enHour(time) : time);
  return (
    <Flex
      css={`
        ${color}
        * {
          font-size: 14px;
          font-family: 'Open Sans', sans-serif;
          text-transform: lowercase;
          line-height: 1.71;
        }
      `}
      justifyContent="space-between"
      color="darkGrey"
    >
      <FormattedMessage id={`contact.${day}`} />
      <span>
        {formatTime(start)}–{formatTime(end)}
      </span>
    </Flex>
  );
};

const ContactData = ({ intl: { locale } }) => {
  return (
    <Box
      as="section"
      css={`
        position: relative;
        max-width: 1920px;
      `}
      width="100vw"
      pb={150}
      px={36}
      mb={{ mobile: 100, laptop: 140 }}
    >
      <Flex flexDirection="column" alignItems="center" mt={100}>
        <h1 css={space} mt={0} mb={10}>
          {TITLE}
        </h1>
        <h3
          css={compose(
            space,
            color,
            fontSize,
          )}
          mt={0}
          mb={{ mobile: 20, laptop: 50 }}
          color="grey"
          fontSize={[16, 22]}
        >
          {ADRESS}
        </h3>
        <Box css={maxWidth} width={[1, 320]} maxWidth={320}>
          {Object.keys(WORKING_HOURS).map(dayOfWeek => (
            <WorkingHoursRow
              key={dayOfWeek}
              day={dayOfWeek}
              workingHours={WORKING_HOURS[dayOfWeek]}
              locale={locale}
            />
          ))}
        </Box>
        <LeftFlowers
          css={`
            top: -33px;
            left: -700px;
          `}
        />
        <RightFlowers
          css={`
            top: -120px;
            left: 400px;
          `}
        />
        <LookAtButton />
      </Flex>
    </Box>
  );
};

export default injectIntl(ContactData);
