import React from 'react';
import PropTypes from 'prop-types';

import { compose, space, color, width, height, fontSize } from 'styled-system';

import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import Link from './Link';

import PhoneIcon from '../assets/phone-icon';

import { PHONE_NUMBER } from '../constants';

const formattedNumber = PHONE_NUMBER.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1-');

const CallUs = ({ section }) => (
  <Flex as={section && 'section'} flexDirection="column" alignItems="center">
    <Flex
      as="h2"
      flexDirection={{ mobile: 'column', laptop: 'row' }}
      mt={0}
      mb={{ mobile: 30, laptop: 50 }}
      px={29}
    >
      <FormattedMessage id="menu.want to order" />
      <Link
        external
        to={`tel: ${PHONE_NUMBER}`}
        css={compose(space, color)}
        ml={{ mobile: 0, laptop: 10 }}
        color="green"
      >
        <FormattedMessage id="menu.call" />
      </Link>
    </Flex>
    <Flex
      as={Link}
      external
      to={`tel: ${PHONE_NUMBER}`}
      css={compose(fontSize, color)}
      alignItems="center"
      fontSize={{ mobile: 30, laptop: 40 }}
      color="black"
    >
      <PhoneIcon
        css={compose(width, height, space)}
        width={{ mobile: 30, laptop: 50 }}
        height={{ mobile: 30, laptop: 50 }}
        mr={{ mobile: 10, laptop: 20 }}
      />
      {formattedNumber}
    </Flex>
  </Flex>
);

CallUs.propTypes = {
  section: PropTypes.bool,
};

export default CallUs;
