import React from 'react';

const PhoneIcon = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        width: 100%;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M24.999 0a25 25 0 1 0 25 25 25 25 0 0 0-25-25zm13.2 36.478l-2 2a3.689 3.689 0 0 1-1.437.58 23.61 23.61 0 0 1-23.825-23.88 3.726 3.726 0 0 1 .586-1.373l2-2a3.344 3.344 0 0 1 3.125-.739l.421.14a3.955 3.955 0 0 1 2.286 2.417l1.007 3.7a3.625 3.625 0 0 1-.827 3.165l-1.336 1.333a14.2 14.2 0 0 0 9.983 9.986l1.336-1.337a3.623 3.623 0 0 1 3.166-.831l3.695 1.01a3.96 3.96 0 0 1 2.42 2.283l.14.427a3.348 3.348 0 0 1-.74 3.119z"
        data-name="Path 242"
        fill="#58851c"
      />
    </svg>
  );
};

export default PhoneIcon;
